import hljs from 'highlight.js';
const { unregisterLanguage } = hljs;

unregisterLanguage('1c');
unregisterLanguage('abnf');
unregisterLanguage('accesslog');
unregisterLanguage('actionscript');
unregisterLanguage('ada');
unregisterLanguage('angelscript');
unregisterLanguage('apache');
unregisterLanguage('applescript');
unregisterLanguage('arcade');
unregisterLanguage('arduino');
unregisterLanguage('armasm');
unregisterLanguage('asciidoc');
unregisterLanguage('aspectj');
unregisterLanguage('autohotkey');
unregisterLanguage('autoit');
unregisterLanguage('avrasm');
unregisterLanguage('awk');
unregisterLanguage('axapta');
unregisterLanguage('basic');
unregisterLanguage('bnf');
unregisterLanguage('brainfuck');
unregisterLanguage('c-like');
unregisterLanguage('cal');
unregisterLanguage('capnproto');
unregisterLanguage('ceylon');
unregisterLanguage('clean');
unregisterLanguage('clojure-repl');
unregisterLanguage('clojure');
unregisterLanguage('cmake');
unregisterLanguage('coffeescript');
unregisterLanguage('coq');
unregisterLanguage('cos');
unregisterLanguage('crmsh');
unregisterLanguage('crystal');
unregisterLanguage('csp');
unregisterLanguage('d');
unregisterLanguage('dart');
unregisterLanguage('delphi');
unregisterLanguage('django');
unregisterLanguage('dns');
unregisterLanguage('dos');
unregisterLanguage('dsconfig');
unregisterLanguage('dts');
unregisterLanguage('dust');
unregisterLanguage('ebnf');
unregisterLanguage('elixir');
unregisterLanguage('elm');
unregisterLanguage('erb');
unregisterLanguage('erlang-repl');
unregisterLanguage('erlang');
unregisterLanguage('excel');
unregisterLanguage('fix');
unregisterLanguage('flix');
unregisterLanguage('fortran');
unregisterLanguage('fsharp');
unregisterLanguage('gams');
unregisterLanguage('gauss');
unregisterLanguage('gcode');
unregisterLanguage('gherkin');
unregisterLanguage('glsl');
unregisterLanguage('gml');
unregisterLanguage('golo');
unregisterLanguage('gradle');
unregisterLanguage('groovy');
unregisterLanguage('haml');
unregisterLanguage('handlebars');
unregisterLanguage('haskell');
unregisterLanguage('haxe');
unregisterLanguage('hsp');
unregisterLanguage('htmlbars');
unregisterLanguage('hy');
unregisterLanguage('inform7');
unregisterLanguage('irpf90');
unregisterLanguage('isbl');
unregisterLanguage('javascript');
unregisterLanguage('jboss-cli');
unregisterLanguage('julia-repl');
unregisterLanguage('julia');
unregisterLanguage('lasso');
unregisterLanguage('latex');
unregisterLanguage('ldif');
unregisterLanguage('leaf');
unregisterLanguage('lisp');
unregisterLanguage('livecodeserver');
unregisterLanguage('livescript');
unregisterLanguage('llvm');
unregisterLanguage('lsl');
unregisterLanguage('lua');
unregisterLanguage('makefile');
unregisterLanguage('mathematica');
unregisterLanguage('matlab');
unregisterLanguage('maxima');
unregisterLanguage('mel');
unregisterLanguage('mercury');
unregisterLanguage('mipsasm');
unregisterLanguage('mizar');
unregisterLanguage('mojolicious');
unregisterLanguage('monkey');
unregisterLanguage('moonscript');
unregisterLanguage('n1ql');
unregisterLanguage('nim');
unregisterLanguage('nix');
unregisterLanguage('node-repl');
unregisterLanguage('nsis');
unregisterLanguage('objectivec');
unregisterLanguage('ocaml');
unregisterLanguage('openscad');
unregisterLanguage('oxygene');
unregisterLanguage('parser3');
unregisterLanguage('perl');
unregisterLanguage('pf');
unregisterLanguage('pony');
unregisterLanguage('processing');
unregisterLanguage('profile');
unregisterLanguage('protobuf');
unregisterLanguage('puppet');
unregisterLanguage('purebasic');
unregisterLanguage('q');
unregisterLanguage('qml');
unregisterLanguage('r');
unregisterLanguage('reasonml');
unregisterLanguage('rib');
unregisterLanguage('roboconf');
unregisterLanguage('routeros');
unregisterLanguage('rsl');
unregisterLanguage('ruby');
unregisterLanguage('ruleslanguage');
unregisterLanguage('sas');
unregisterLanguage('scala');
unregisterLanguage('scheme');
unregisterLanguage('scilab');
unregisterLanguage('smali');
unregisterLanguage('smalltalk');
unregisterLanguage('sml');
unregisterLanguage('sqf');
unregisterLanguage('sql_more');
unregisterLanguage('stan');
unregisterLanguage('stata');
unregisterLanguage('step21');
unregisterLanguage('stylus');
unregisterLanguage('subunit');
unregisterLanguage('taggerscript');
unregisterLanguage('tap');
unregisterLanguage('tcl');
unregisterLanguage('thrift');
unregisterLanguage('tp');
unregisterLanguage('twig');
unregisterLanguage('vala');
unregisterLanguage('vbnet');
unregisterLanguage('vbscript-html');
unregisterLanguage('vbscript');
unregisterLanguage('verilog');
unregisterLanguage('vhdl');
unregisterLanguage('vim');
unregisterLanguage('x86asm');
unregisterLanguage('xl');
unregisterLanguage('xquery');
unregisterLanguage('zephir');
